@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-family: FontAwesome !default;
$slick-dot-color: $main-theme !default;
$slick-prev-character: "\f137" !default;
$slick-next-character: "\f138" !default;
$slick-dot-character: "\f111" !default;
$slick-button-position: 30px !default;
$slick-button-size: 40px !default;
$slick-dot-size: 16px !default;
$slick-dots-position: 10px !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-opacity-default: 0.5 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
$slick-button-size: 20px !default;
$slick-font-size: $slick-button-size !default;
$slick-button-position: -25px !default;
$slick-dots-position: -25px !default;

/* Arrows */

html.keyboard-nav .slick-slider,
.slick-slider:hover {

    .slick-arrow {
        &::before {
            opacity: $slick-opacity-default;
            transition: opacity 0.5s linear;
        }
        &:hover, &:focus {
            &::before {
                opacity: $slick-opacity-on-hover;
            }
        }
    }

    .slick-dots {
        opacity: $slick-opacity-on-hover;
        transition: opacity 0.5s linear;
    }
}


.slick-arrow {
    position: absolute;
    display: block;
    height: $slick-button-size;
    width: $slick-button-size;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 1100;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
    }
    &.slick-disabled::before {
        opacity: $slick-opacity-not-active;
    }
    &::before {
        content: " ";
        display: block;
        width: $slick-button-size;
        height:  $slick-button-size;
        background-size: $slick-button-size $slick-button-size;
        opacity: 0;
        transition: opacity 0.5s linear;
    }
}

.slick-prev {
    left: $slick-button-position;
    [dir="rtl"] & {
        left: auto;
        right: $slick-button-position;
    }
    &::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTM5IiBoZWlnaHQ9IjI1NiIgdmlld0JveD0iMCAwIDEzOSAyNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMzUgMUw3IDEyOC40OTZMMTMyLjUgMjU3IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjkiLz4KPC9zdmc+Cg==);
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: $slick-button-position;
    [dir="rtl"] & {
        left: $slick-button-position;
        right: auto;
    }
    &::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTM2IiBoZWlnaHQ9IjI1NiIgdmlld0JveD0iMCAwIDEzNiAyNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00IDFMMTMyIDEyOC40OTZMNi41IDI1NyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI5Ii8+Cjwvc3ZnPgo=);
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.slick-dots {
    position: absolute;
    bottom: $slick-dots-position;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 1100;
    opacity: 0;
    transition: opacity 0.5s linear;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &::before {
                    opacity: $slick-opacity-on-hover;
                    background-size: $slick-dot-size $slick-dot-size;
                }
            }
            &::before {
                content: " ";
                display: block;
                width: $slick-dot-size;
                height: $slick-dot-size;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: ($slick-dot-size*0.75) ($slick-dot-size*0.75);
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjU2IiBoZWlnaHQ9IjI1NiIgdmlld0JveD0iMCAwIDI1NiAyNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjEyOCIgY3k9IjEyOCIgcj0iMTAzIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
                position: absolute;
                top: 0;
                left: 0;
                opacity: $slick-opacity-default;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button::before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-on-hover;
            background-size: $slick-dot-size $slick-dot-size;
      }
    }
}