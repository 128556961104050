/**********
 * Theme file: theme-main.scss
 */
$template-img-path : '../img/';

// Main theme colors
$main-theme:                    #333;
$main-theme-hover:              #C16633;

// Import the other files
@import '../demo-defaults';


@import '../imports';