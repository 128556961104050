

.separator {
  margin: 10px auto;
  background: url("../img/line-full.png") no-repeat center center / contain;
  width: 440px;
  height: 80px;
  opacity: 0.7;

  &.monogram {
    background: url("../img/separator-monogram.svg") no-repeat center center / contain;
    width: 159px;
    height: 33px;
    opacity: 1;
  }
}

.main-title {
  display: block;
  text-align: center;
  position: relative;
  margin-top: 40px;
  margin-bottom: 35px;

  > h1 {
    display: inline-block;
    position: relative;
    line-height: 1;

    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";
      width: 160px;
      height: 42px;
      opacity: 0.7;
      top: 2px;
    }

    &::before {
      background: url("../img/line-left.png") no-repeat left center / contain;
      left: -175px;
    }

    &::after {
      background: url("../img/line-right.png") no-repeat right center / contain;
      right: -175px;
    }
  }
}

.line + .main-title {
  top: -42px;
}
.h-bg {
  > img {
    width: 100%;
    max-width: 100%;
  }
}
.btn-social-icon {
  background-color: transparent;
  color: $foot-sub-link-color;
  padding: 0.6rem 0.8rem;
  font-size: 3.2rem;
  border: none;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: $foot-sub-link-hover-color;
    border: none;
  }

  &::after {
    display: none;
  }
}

.btn:not(.dropdown-toggle):not(.li-label):not(.btn-social-icon) {

  &::after {
    display: inline-block;
    content: "";
    height: 15px;
    width: 21px;
    vertical-align: middle;
    margin-left: 10px;

    background: url("../img/arrow.svg") no-repeat center center / contain;
  }

  &:hover {
    &::after {
      background-image: url("../img/arrow-hover.svg");
    }
  }
}